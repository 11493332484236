<template>
    <div>
        <h1 class="title is-4">
            <template v-if="$route.params.id">{{ $t("Interface.Key.Edit.Title") + ` "${Form.Description}"` }}</template>
            <template v-else>{{ $t("Interface.Key.Create.Title") }}</template>
        </h1>
        <ValidationObserver
            v-slot="{ handleSubmit, invalid }"
            tag="form"
            class="form box"
        >
            <div class="columns is-multiline">
                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-full"
                    :name="$t('Interface.Key.Description')"
                >
                    <b-field
                        :label="$t('Interface.Key.Description')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-input v-model="Form.Description" type="text" />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Key.Expiration')"
                >
                    <b-field
                        :label="$t('Interface.Key.Expiration')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-datetimepicker
                            ref="datepicker"
                            v-model="Form.Expiration"
                            :min-datetime="new Date()"
                            :locale="CurrentLocale"
                            :datetime-formatter="dateTimeFormatter"
                            :loading="IsLoading"
                            editable
                            required
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required|numeric|min_value:1"
                    class="column is-half"
                    :name="$t('Interface.Key.AvailableAmount')"
                >
                    <b-field
                        :label="$t('Interface.Key.AvailableAmount')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        expanded
                    >
                        <b-input v-model="Form.AvailableAmount" type="number" min="1" />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required|float|min_value:0"
                    class="column is-half"
                    :name="$t('Interface.Key.Price')"
                >
                    <b-field
                        :label="$t('Interface.Key.Price')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        :addons="false"
                    >
                        <b-input v-model="Form.Price" type="text" :disabled="PriceFree" />
                        <div class="block">
                            <b-checkbox v-model="PriceFree">
                                {{ $t("Interface.Key.PriceFree") }}
                            </b-checkbox>
                        </div>
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.Key.Currency')"
                >
                    <b-field
                        :label="$t('Interface.Key.Currency')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-select v-model="Form.Currency" expanded :disabled="PriceFree">
                            <option v-for="Currency in Currencys" :key="Currency.code" :value="Currency.code">
                                {{ Currency.name }}
                            </option>
                        </b-select>
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required|integer|max_value:9223372036854770|min_value:-1"
                    class="column is-half"
                    :name="$t('Interface.Key.OpensLimit')"
                >
                    <b-field
                        :label="$t('Interface.Key.OpensLimit')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        :addons="false"
                    >
                        <b-input
                            v-model="Form.OpensLimit"
                            type="number"
                            min="-1"
                            max="9223372036854770"
                            :disabled="OpensUnlimited"
                        />
                        <div class="block">
                            <b-checkbox v-model="OpensUnlimited">
                                {{ $t("Interface.Key.OpensLimits.Unlimited") }}
                            </b-checkbox>
                        </div>
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required|integer|max_value:9223372036854770|min_value:-1"
                    class="column is-half"
                    :name="$t('Interface.Key.OpenDistance')"
                >
                    <b-field
                        :label="$t('Interface.Key.OpenDistance')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        :addons="false"
                    >
                        <b-input
                            v-model="Form.OpenDistance"
                            type="number"
                            min="-1"
                            max="9223372036854770"
                            :disabled="DistanceUnlimited"
                        />
                        <div class="block">
                            <b-checkbox v-model="DistanceUnlimited">
                                {{ $t("Interface.Key.OpensLimits.Unlimited") }}
                            </b-checkbox>
                        </div>
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required|numeric|min_value:1"
                    class="column is-half"
                    :name="$t('Interface.Key.DurationSeconds')"
                >
                    <b-field
                        :label="$t('Interface.Key.DurationSeconds')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                        :addons="false"
                    >
                        <b-input v-model="Form.DurationSeconds" type="number" min="1" />
                        <div class="block">
                            <b-radio
                                v-for="DurationOption in Durations"
                                :key="DurationOption.name"
                                v-model="Form.Multiplicator"
                                :value="DurationOption.multiplier"
                                :native-value="DurationOption.multiplier"
                            >
                                {{ DurationOption.name }}
                            </b-radio>
                        </div>
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-full"
                    :name="$t('Interface.Key.TermsOfUse')"
                >
                    <div class="field">
                        <label class="label">
                            {{ $t("Interface.Key.TermsOfUse") }}
                        </label>
                        <textarea
                            v-model="Form.TermsOfUse"
                            rows="2"
                            class="textarea"
                            :class="{ 'is-danger': errors[0], 'is-success': valid }"
                        />
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </div>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ errors }"
                    mode="aggressive"
                    tag="div"
                    :name="$t('Interface.Key.List.Hidden')"
                    class="column is-full"
                >
                    <b-checkbox
                        v-model="Form.Hidden"
                        type="is-danger"
                        :checked="Form.Hidden"
                        :native-value="Form.Hidden"
                        name="Checkbox"
                        :true-value="true"
                        :false-value="false"
                        required
                    >
                        {{ $t("Interface.Key.Hidden") }}
                    </b-checkbox>
                    <p v-if="errors[0]" class="help is-danger">
                        <span class="goto-icon icon is-small is-right">
                            <i class="goto-icon-error" />
                        </span>
                        {{ errors[0] }}
                    </p>
                </ValidationProvider>
            </div>

            <hr>
            <h6 class="title is-6">{{ $t("Interface.Key.AssociatedDoors") }}</h6>
            <div class="columns is-multiline">
                <div class="column is-full">
                    <b-field
                        v-for="(line, index) in AssociatedDoors"
                        :key="index"
                        grouped
                    >
                        <ValidationProvider
                            v-slot="{ valid, errors }"
                            mode="aggressive"
                            tag="div"
                            :rules="{ required: { allowFalse: false } }"
                            class="control is-radio"
                            :name="$t('Interface.Key.MainDoor')"
                        >
                            <b-field
                                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                :message="errors[0]"
                            >
                                <b-radio
                                    v-model="MainDoor"
                                    :value="AssociatedDoors[index].doorId"
                                    :native-value="AssociatedDoors[index].doorId"
                                    :disabled="isObjectEmpty(AssociatedDoors[index])"
                                >
                                    {{ $t("Interface.Key.MainDoor") }}
                                </b-radio>
                            </b-field>
                        </ValidationProvider>
                        <ValidationProvider
                            v-slot="{ valid, errors }"
                            tag="div"
                            class="control is-expanded"
                            rules="required"
                            :name="$t('Interface.Key.Create.DoorName')"
                        >
                            <b-field
                                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                :message="errors[0]"
                            >
                                <b-select
                                    v-model="AssociatedDoors[index]"
                                    :placeholder="$t('Interface.Key.Create.ChooseDoor')"
                                    expanded
                                    @input="changeDoor"
                                >
                                    <option
                                        v-for="Door in Doors"
                                        :key="Door.doorId"
                                        :value="Door"
                                        :disabled="checkDoorIsNotAllowed(Door.doorId)"
                                    >
                                        {{ Door.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </ValidationProvider>
                        <p v-if="!BlockRemoval" class="control">
                            <button
                                type="buttom"
                                class="button is-danger"
                                @click.prevent="removeLine(index)"
                            >
                                <b-icon icon="minus" />
                            </button>
                        </p>
                    </b-field>
                </div>
            </div>

            <button
                v-if="!AddingNewLinesIsNOTAllowed"
                type="buttom"
                class="button is-info"
                @click.prevent="addLine"
            >
                <b-icon icon="plus" />
            </button>
            <br>
            <br>
            <br>

            <button
                type="submit"
                class="button is-theme"
                :disabled="invalid || !MainDoor"
                @click.prevent="handleSubmit(submit)"
            >
                {{ $t("Interface.Button.Submit") }}
            </button>
        </ValidationObserver>
        <b-loading
            :is-full-page="true"
            :active.sync="IsLoading"
            :can-cancel="false"
        />
    </div>
</template>

<script>
import Helpers from "@/utils/Helpers"
import LoadingState from "@/mixins/LoadingState"
import { DOOR_LIST_REQUEST } from "@/store/actions/door"
import {
    KEY_ADD_REQUEST,
    KEY_EDIT_REQUEST,
    KEY_LIST_REQUEST
} from "@/store/actions/key"

export default {
    name: "KeyCreate",
    mixins: [LoadingState],
    data() {
        return {
            BlockRemoval: true,
            Form: {
                Description: null,
                Expiration: null,
                AvailableAmount: null,
                Currency: null,
                Price: null,
                DurationSeconds: null,
                OpensLimit: null,
                OpenDistance: null,
                TermsOfUse: null,
                DoorsIds: [],
                Multiplicator: 60,
                Hidden: true
            },
            PriceFree: false,
            OpensUnlimited: false,
            DistanceUnlimited: false,
            MainDoor: 0,
            AssociatedDoors: []
        }
    },
    computed: {
        Doors() {
            return this.$store.state.Door.List
        },
        Key() {
            return this.$store.getters.GET_KEY_BY_ID(parseInt(this.$route.params.id, 10))
        },
        AddingNewLinesIsNOTAllowed() {
            if (this.AssociatedDoors && this.AssociatedDoors.length > 0) {
                const checkEmptyLines = this.AssociatedDoors.filter((line) => Helpers.isObjectEmpty(line))
                return (
                    (checkEmptyLines.length >= 1 && this.AssociatedDoors.length > 0)
                    || this.AssociatedDoors.length === this.Doors.length
                )
            }
            return false
        },
        Currencys() {
            return [
                {
                    code: "RUB",
                    name: this.$t("Interface.Currency.RUB")
                },
                {
                    code: "USD",
                    name: this.$t("Interface.Currency.USD")
                }
            ]
        },
        Durations() {
            return [
                {
                    name: this.$t("Interface.Key.Duration.Minutes"),
                    multiplier: 60
                },
                {
                    name: this.$t("Interface.Key.Duration.Hours"),
                    multiplier: 3600
                },
                {
                    name: this.$t("Interface.Key.Duration.Days"),
                    multiplier: 86400
                }
            ]
        },
        CurrentLocale() {
            return this.$i18n.locale
        }
    },
    watch: {
        AssociatedDoors() {
            this.BlockRemoval = this.AssociatedDoors.length <= 1
        },
        PriceFree() {
            if (this.PriceFree) {
                this.Form.Price = 0
                this.Form.Currency = (this.Form.Currency) ? this.Form.Currency : this.Currencys[0].code
            }
            else this.Form.Price = ""
        },
        OpensUnlimited() {
            if (this.OpensUnlimited) this.Form.OpensLimit = -1
            else this.Form.OpensLimit = ""
        },
        DistanceUnlimited() {
            if (this.DistanceUnlimited) this.Form.OpenDistance = -1
            else this.Form.OpenDistance = 0
        }
    },
    mounted() {
        this.getDoors()
            .then(() => {
                if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined") {
                    if (!this.$store.getters.IS_KEYS_LOADED) {
                        this.switchLoading()
                        this.$store.dispatch(KEY_LIST_REQUEST)
                            .then(() => this.getData())
                            .finally(() => this.switchLoading())
                    }
                    else this.getData()
                }
            })
    },
    methods: {
        changeDoor(value) {
            const MDoors = this.AssociatedDoors.find((x) => x.doorId === this.MainDoor)
            if (!MDoors) this.MainDoor = value.doorId
        },
        isObjectEmpty(obj) {
            return Helpers.isObjectEmpty(obj)
        },
        getDoors() {
            return new Promise((resolve) => {
                if (!this.$store.getters.IS_DOORS_LOADED) {
                    this.switchLoading()
                    this.$store.dispatch(DOOR_LIST_REQUEST)
                        .then(() => this.fillFirstDoor())
                        .finally(() => {
                            this.switchLoading()
                            resolve(false)
                        })
                }
                else {
                    this.fillFirstDoor()
                    resolve(true)
                }
            })
        },
        getData() {
            this.Form.Description = this.Key.description
            // Конвертация даты из локального в формат UTC со смещением по часовому поясу
            this.Form.Expiration = new Date(this.Key.expiration)
            this.Form.AvailableAmount = this.Key.availableAmount
            this.Form.Currency = this.Key.currency
            this.Form.Price = this.Key.price
            this.PriceFree = (parseInt(this.Key.price, 10) === 0)
            this.Form.OpensLimit = this.Key.opensLimit
            this.Form.OpenDistance = this.Key.openDistance
            this.DistanceUnlimited = (this.Key.openDistance === -1)
            this.Form.TermsOfUse = this.Key.termsOfUse
            this.Form.Hidden = this.Key.hidden
            this.Form.Multiplicator = (this.Key.multiplicator) ? this.Key.multiplicator : 60
            this.Form.DurationSeconds = parseInt(this.Key.durationSeconds, 10) / this.Form.Multiplicator
            if (this.Key.doors && this.Key.doors.length > 0) {
                this.Key.doors.forEach((element) => {
                    this.AssociatedDoors.push(this.Doors.find((x) => x.doorId === element.doorId))
                })
            }
            const Mdoor = this.Key.doors.find((x) => x.main === true)
            this.MainDoor = (Mdoor) ? Mdoor.doorId : null
            this.OpensUnlimited = (this.Form.OpensLimit === -1)
            this.$refs.datepicker.toggle()
        },
        submit() {
            this.switchLoading()
            const Form = JSON.parse(JSON.stringify(this.Form))
            // Конвертация длительности действия ключа в секунды
            Form.DurationSeconds = parseInt(Form.DurationSeconds, 10) * this.Form.Multiplicator
            // Сбор необходимого массива дверей из выбранных
            this.AssociatedDoors.forEach((element) => {
                Form.DoorsIds.push({
                    Id: element.doorId,
                    Main: (this.MainDoor === element.doorId)
                })
            })
            // Конвертация даты из локального в формат UTC со смещением по часовому поясу
            Form.Expiration = new Date(Form.Expiration).toISOString()

            if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined") {
                this.$store.dispatch(KEY_EDIT_REQUEST, { Form, Id: this.$route.params.id })
                    .then(() => {
                        this.$store.dispatch(KEY_LIST_REQUEST)
                            .then(() => this.$router.push({ name: "Key-List" }))
                    })
                    .finally(() => this.switchLoading())
            }
            else {
                this.$store.dispatch(KEY_ADD_REQUEST, Form)
                    .then(() => {
                        this.$store.dispatch(KEY_LIST_REQUEST)
                            .then(() => this.$router.push({ name: "Key-List" }))
                    })
                    .finally(() => this.switchLoading())
            }
        },
        fillFirstDoor() {
            if (!this.$route.params.id && !typeof (this.$route.params.id) !== "undefined" && this.Doors && this.Doors.length > 0) {
                this.AssociatedDoors.push(this.Doors[0])
                this.MainDoor = this.AssociatedDoors[0].doorId
            }
        },
        addLine() {
            this.AssociatedDoors.push({})
        },
        removeLine(lineId) {
            if (!this.BlockRemoval) {
                if (this.MainDoor === this.AssociatedDoors[lineId].doorId) this.MainDoor = this.AssociatedDoors[0].doorId
                this.AssociatedDoors.splice(lineId, 1)
            }
        },
        checkDoorIsNotAllowed(Id) {
            return this.AssociatedDoors.find((x) => x.doorId === Id) !== undefined
        },
        dateTimeFormatter(time) {
            return Helpers.filterDateTime(time)
        }
    },
    metaInfo() {
        return {
            title: (this.$route.params.id) ? this.$t("Interface.Key.Edit.Title") : this.$t("Interface.Key.Create.Title")
        }
    }
}
</script>

<style>

</style>
